import React from 'react';

const FilterBar = ({ categoryFilter, setCategoryFilter }) => {
  return (
    <select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}>
      <option value="">Todos</option>
      <option value="prieto">Prietos</option>
      <option value="integral">Integrales</option>
      <option value="bollo">Bollos</option>
      <option value="prenao">Preñaos</option>
    </select>
  );
};

export default FilterBar;
