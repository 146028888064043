// src/components/Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, bread }) => {
  if (!show) return null;

  // Mapeo de categorías
  const categoryNames = {
    prieto: 'Prietos',
    integral: 'Integrales',
    bollo: 'Bollos',
    prenao: 'Preñaos',
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={bread.imageUrl} alt={bread.name} className="modal-image" />
        <h2>{bread.name}</h2>
        <div className="modal-section">
          <h4>Categoría</h4>
          <p>{categoryNames[bread.category]}</p>
        </div>
        <div className="modal-section">
          <h4>Medidas</h4>
          <p>{bread.dimensions}</p>
        </div>
        <div className="modal-section">
          <h4>Descripción</h4>
          <p>{bread.description}</p>
        </div>
        <button className="modal-close" onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default Modal;
