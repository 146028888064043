import React from 'react';
import { MdFreeBreakfast, MdEvent, MdRoomService, MdBreakfastDining } from 'react-icons/md'; 
import './IconFilterBar.css';

const IconFilterBar = ({ selectedIconFilter, setSelectedIconFilter }) => {
  return (
    <div className="icon-filter-bar">
      <div
        className={`icon-filter ${selectedIconFilter === '' ? 'active' : ''}`}
        onClick={() => setSelectedIconFilter('')}
      >
        <MdBreakfastDining size={30} />
        <p>Todos</p>
      </div>
      <div
        className={`icon-filter ${selectedIconFilter === 'servicio' ? 'active' : ''}`}
        onClick={() => setSelectedIconFilter('servicio')}
      >
        <MdRoomService size={30} />
        <p>Servicio</p>
      </div>
      <div
        className={`icon-filter ${selectedIconFilter === 'desayuno' ? 'active' : ''}`}
        onClick={() => setSelectedIconFilter('desayuno')}
      >
        <MdFreeBreakfast size={30} />
        <p>Desayunos</p>
      </div>
      <div
        className={`icon-filter ${selectedIconFilter === 'eventos' ? 'active' : ''}`}
        onClick={() => setSelectedIconFilter('eventos')}
      >
        <MdEvent size={30} />
        <p>Eventos</p>
      </div>
    </div>
  );
};

export default IconFilterBar;
