import React, { useState, useEffect } from 'react';
import breadData from './breadData';
import SearchBar from './components/SearchBar';
import FilterBar from './components/FilterBar';
import IconFilterBar from './components/IconFilterBar';
import BreadList from './components/BreadList';
import Modal from './components/Modal';
import Footer from './components/Footer';
import './App.css';
import bannerImage from './assets/banner.jpg'; // Importar la imagen desde assets

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [iconFilter, setIconFilter] = useState('');
  const [visibleBreads, setVisibleBreads] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBread, setSelectedBread] = useState(null);

  const filteredBreads = breadData
    .filter((bread) =>
      bread.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((bread) => (categoryFilter ? bread.category === categoryFilter : true))
    .filter((bread) => (iconFilter ? bread.additionalCategory === iconFilter : true));

  const visibleBreadItems = filteredBreads.slice(0, visibleBreads);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      visibleBreads < filteredBreads.length &&
      !isLoading
    ) {
      setIsLoading(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [visibleBreads, filteredBreads.length, isLoading]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setVisibleBreads((prevVisibleBreads) => prevVisibleBreads + 15);
        setIsLoading(false);
      }, 1000);
    }
  }, [isLoading]);

  const openModal = (bread) => {
    setSelectedBread(bread);
  };

  const closeModal = () => {
    setSelectedBread(null);
  };

  return (
    <div>
      <nav className="navbar">
        <h1>Catálogo de Piezas</h1>
      </nav>

      {/* Banner con la imagen completa */}
      <div className="banner-container">
      </div>

      <div className="content">
        <div className="search-filter-bar">
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          <FilterBar categoryFilter={categoryFilter} setCategoryFilter={setCategoryFilter} />
        </div>
        <IconFilterBar selectedIconFilter={iconFilter} setSelectedIconFilter={setIconFilter} />
        <BreadList breads={visibleBreadItems} onBreadClick={openModal} />
        {isLoading && <div className="loader"></div>}
        <Modal show={!!selectedBread} bread={selectedBread} onClose={closeModal} />
        <Footer />
      </div>
    </div>
  );
}

export default App;
