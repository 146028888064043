// src/components/BreadList.js
import React from 'react';
import BreadItem from './BreadItem';

const BreadList = ({ breads, onBreadClick }) => {
  return (
    <div className="bread-list">
      {breads.map((bread) => (
        <BreadItem
          key={bread.id}
          bread={bread}
          onClick={() => onBreadClick(bread)}
        />
      ))}
    </div>
  );
};

export default BreadList;
