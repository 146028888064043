const breadData = [
    {
      id: 1,
      name: 'Prieto',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 2,
      name: 'Pepito Prieto',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 3,
      name: 'Prieto Mediano',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 4,
      name: 'Bollo mediano',
      category: 'bollo',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://polvillo.es/wp-content/uploads/2014/01/bollo-1.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 5,
      name: 'Bollito sevillano',
      category: 'bollo',
      description: 'Pan hojaldrado suave con un delicioso sabor mantecoso.',
      dimensions: '10x15 cm',
      imageUrl: 'https://polvillo.es/wp-content/uploads/2014/01/bollo-1.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 6,
      name: 'Prieto Integral',
      category: 'integral',
      description: 'Hecho con harina integral, perfecto para una opción más saludable.',
      dimensions: '10x15 cm',
      imageUrl: 'https://panaderiaelbrillante.com/wp-content/uploads/pan-100-integral.jpg',
      additionalCategory: 'desayuno',
    },
    {
      id: 7,
      name: 'Pepito Integral',
      category: 'integral',
      description: 'Hecho con harina integral, perfecto para una opción más saludable.',
      dimensions: '10x15 cm',
      imageUrl: 'https://panaderiaelbrillante.com/wp-content/uploads/pan-100-integral.jpg',
      additionalCategory: 'desayuno',
    },
    {
      id: 8,
      name: 'Preñaita de Roquefort',
      category: 'prenao',
      description: 'Pan dulce con trozos de chocolate, ideal para un postre o merienda.',
      dimensions: '10x15 cm',
      imageUrl: 'https://www.nikunorte.es/udecontrol_datos/objetos/20.jpg',
      additionalCategory: 'eventos',
    },
    {
      id: 9,
      name: 'Preñaita de Chorizo',
      category: 'prenao',
      description: 'Pan dulce con trozos de chocolate, ideal para un postre o merienda.',
      dimensions: '10x15 cm',
      imageUrl: 'https://www.nikunorte.es/udecontrol_datos/objetos/20.jpg',
      additionalCategory: 'eventos',
    },
    {
      id: 10,
      name: 'Preñaita de Chorizo Picante',
      category: 'prenao',
      description: 'Pan dulce con trozos de chocolate, ideal para un postre o merienda.',
      dimensions: '10x15 cm',
      imageUrl: 'https://www.nikunorte.es/udecontrol_datos/objetos/20.jpg',
      additionalCategory: 'eventos',
    },
    {
      id: 11,
      name: 'Preñaita de Morcilla',
      category: 'prenao',
      description: 'Pan dulce con trozos de chocolate, ideal para un postre o merienda.',
      dimensions: '10x15 cm',
      imageUrl: 'https://www.nikunorte.es/udecontrol_datos/objetos/20.jpg',
      additionalCategory: 'eventos',
    },
    {
      id: 12,
      name: 'Prieto Cartuja',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 13,
      name: 'Pepito Cartuja',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 14,
      name: 'Pepito Prieto mini',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'servicio',
    },
    {
      id: 15,
      name: 'Pepito Prieto Rústico',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'servicio',
    },
    {
      id: 16,
      name: 'Pulga Casa',
      category: 'bollo',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://polvillo.es/wp-content/uploads/2014/01/bollo-1.png',
      additionalCategory: 'servicio',
    },
    {
      id: 17,
      name: 'Prieto Mediano Multicereal',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },
    {
      id: 18,
      name: 'Pepito Multicereal',
      category: 'prieto',
      description: 'Un pan francés clásico con una corteza crujiente y un interior suave.',
      dimensions: '10x15 cm',
      imageUrl: 'https://pepelnary.com/wp-content/uploads/2019/06/Mini-Prieto.png',
      additionalCategory: 'desayuno',
    },

  ];
  
  export default breadData;