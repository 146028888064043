// src/components/BreadItem.js
import React from 'react';
import './BreadItem.css';

const BreadItem = ({ bread, onClick }) => {
  return (
    <div className="bread-item" onClick={onClick}>
      <img src={bread.imageUrl} alt={bread.name} className="bread-image" />
      <h3>{bread.name}</h3>
      <p>{bread.description}</p>
    </div>
  );
};

export default BreadItem;
